import { QuestionVariantType } from "@/types/assessment-question";

// URLS
export const HOST_URL = process.env.NEXT_PUBLIC_HOST_URL;
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const PARSER_URL = process.env.NEXT_PUBLIC_PARSER_URL;
export const PARSER_V2_URL = process.env.NEXT_PUBLIC_PARSER_V2_URL;
export const CLOUDINARY_URL = process.env.NEXT_PUBLIC_CLOUDINARY_URL;
export const CLOUDINARY_API_KEY = process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY;
export const METABASE_SITE_URL = process.env.NEXT_PUBLIC_METABASE_SITE_URL;
export const METABASE_SECRET_KEY = process.env.NEXT_PUBLIC_METABASE_SECRET_KEY;
export const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;
export const APP_INSIGHTS_CONNECTION_STRING =
  process.env.NEXT_PUBLIC_APP_INSIGHTS_CONNECTION_STRING;

// user type

export const USER_ROLE = {
  RECRUITER: "Recruiter",
  INTERVIEWER: "Interviewer",
  CANDIDATE: "Candidate",
  CLIENT: "Client",
};

// cookie and localStorage key

export const LOCAL_STORAGE = {
  EMPLOYER_SIGNUP_EMAIL: "employerSignupEmail",
  LAST_LOGIN_USER_TYPE: "LAST_LOGIN_USER_TYPE",
  //
  SUGGEST_REQUEST_CANDIDATE_SLOT: "SUGGEST_REQUEST_CANDIDATE_SLOT",
  SUGGEST_ISSUE_OFFER: "SUGGEST_ISSUE_OFFER",
  //
  CURRENT_APPLIED_SAVED_FILTER_ID_IN_RECRUITER_VIEW:
    "CURRENT_APPLIED_SAVED_FILTER_ID_IN_RECRUITER_VIEW",
  //
  LOGGED_IN_USER_ACCORDION_STATE_IN_CALL_LOG:
    "LOGGED_IN_USER_ACCORDION_STATE_IN_CALL_LOG",
};

export const COOKIE = {
  ZELACCESS: "zelAccess",
  ZELREFRESH: "zelRefresh",
  ROLES: "roles",

  //
  EMPLOYER_SIGNUP_REQUEST: "EMPLOYER_SIGNUP_REQUEST",
};

export const firstPermittedRoute: Record<string, string> = {
  [USER_ROLE.CANDIDATE]: "jobs",
  [USER_ROLE.RECRUITER]: "candidates",
  [USER_ROLE.CLIENT]: "recruit/jobs",
  [USER_ROLE.INTERVIEWER]: "assessments",
};

export const LAKH_CURRENCY_MULTIPLIER = 100000;

export const websiteRegex =
  /((http|https)\:\/\/)?[a-zA-Z0-9\.\/\?\:@\-_=#]+\.([a-zA-Z]){2,6}([a-zA-Z0-9\.\&\/\?\:@\-_=#])*/; //eslint-disable-line

export const SHOW_IMMEDIATE_JOINER_BEFORE_DAYS = 3;

export const assessmentQuestionTypes: {
  value: QuestionVariantType;
  label: string;
}[] = [
  { value: "Radio", label: "Single" },
  { value: "Checkbox", label: "Multi-Select" },
];

export const assessmentQuestionTypesMap: Record<
  QuestionVariantType,
  {
    value: QuestionVariantType;
    label: string;
  }
> = {
  Radio: { value: "Radio", label: "Single" },
  Checkbox: { value: "Checkbox", label: "Multi-Select" },
};

export const emptyInterviewerSchedule = {
  Monday: {
    timeData: [],
    isDayChecked: false,
    isInitialTime: false,
    errorMessage: {
      orderError: null,
      overlapError: null,
      wrongTimeError: null,
    },
    max_slots: 0,
  },
  Tuesday: {
    timeData: [],
    isDayChecked: false,
    isInitialTime: false,
    errorMessage: {
      orderError: null,
      overlapError: null,
      wrongTimeError: null,
    },
    max_slots: 0,
  },
  Wednesday: {
    timeData: [],
    isDayChecked: false,
    isInitialTime: false,
    errorMessage: {
      orderError: null,
      overlapError: null,
      wrongTimeError: null,
    },
    max_slots: 0,
  },
  Thursday: {
    timeData: [],
    isDayChecked: false,
    isInitialTime: false,
    errorMessage: {
      orderError: null,
      overlapError: null,
      wrongTimeError: null,
    },
    max_slots: 0,
  },
  Friday: {
    timeData: [],
    isDayChecked: false,
    isInitialTime: false,
    errorMessage: {
      orderError: null,
      overlapError: null,
      wrongTimeError: null,
    },
    max_slots: 0,
  },
  Saturday: {
    timeData: [],
    isDayChecked: false,
    isInitialTime: false,
    errorMessage: {
      orderError: null,
      overlapError: null,
      wrongTimeError: null,
    },
    max_slots: 0,
  },
  Sunday: {
    timeData: [],
    isDayChecked: false,
    isInitialTime: false,
    errorMessage: {
      orderError: null,
      overlapError: null,
      wrongTimeError: null,
    },
    max_slots: 0,
  },
};

export const joiningAvailabilityOptions = [
  { label: "Any", value: "999" },
  {
    label: "Less than 90 days",
    value: "90",
  },
  {
    label: "Less than 60 days",
    value: "60",
  },
  {
    label: "Less than 45 days",
    value: "45",
  },
  {
    label: "Less than 30 days",
    value: "30",
  },
  {
    label: "Less than 15 days",
    value: "15",
  },
  {
    label: "Immediate",
    value: "0",
  },
];
