import axiosInstance from "@/services/api/axiosInstance";

const generateOTP = "generate-otp";
const verifyOTP = "verify-otp";
const user = "user";
const login = "login";
const candidateSignupSendOtp = "candidate-signup-send-otp";
const candidateSignupVerifyOtp = "candidate-signup-verify-otp";
const userRoles = "user-roles";
const employerSignupSendOtp = "employer-signup";
const oneTimeTokenLogin = "one-time-token-login";

export default {
  getOTP(payload: { mobile: string }) {
    return axiosInstance.post(`/${generateOTP}/`, payload);
  },
  verifyOTP(payload: { mobile: string; otp: string }) {
    return axiosInstance.post(`/${verifyOTP}/`, payload);
  },
  getPermissionRules() {
    return axiosInstance.get("/permission/");
  },
  login(payload: { email: string; password: string }) {
    return axiosInstance.post(`/${login}/`, payload);
  },

  getUser(params?: { new_role__name?: "Recruiter"; search?: string }) {
    return axiosInstance.get(`/${user}/`, { params });
  },

  getUserById(id: number) {
    return axiosInstance.get(`/${user}/${id}/`);
  },

  // candidate auth
  postCandidateSignup(payload: {
    name: string;
    email?: string;
    mobile?: string;
    resend_mobile_otp?: boolean;
    resend_email_otp?: boolean;
    session_key?: string;
  }) {
    return axiosInstance.post(`/${candidateSignupSendOtp}/`, payload);
  },
  verifyCandidateOTP(payload: {
    email_otp?: string;
    mobile_otp?: string;
    session_key: string;
  }) {
    return axiosInstance.post(`/${candidateSignupVerifyOtp}/`, payload);
  },

  // employer signup
  postEmployerSignUp(payload: {
    company_name: string;
    name: string;
    mobile: string;
    mobile_code: string;
    email: string;
    industry: number[];
    tech_stack: number[];
  }) {
    return axiosInstance.post(`/${employerSignupSendOtp}/`, payload);
  },

  resendEmployerSignUpOTP(payload: {
    resend_email_otp: true;
    session_key: string;
  }) {
    return axiosInstance.post(`/${employerSignupSendOtp}/`, payload);
  },

  verifyEmployerSignInOTP(payload: {
    email_otp?: string;
    session_key: string;
  }) {
    return axiosInstance.post(`/${employerSignupSendOtp}/`, payload);
  },

  getUserRoles() {
    return axiosInstance.get<{ name: string; id: number }[]>(
      `${userRoles}?pagination=false`,
    );
  },

  getOneTimeTokenLogin(token: string) {
    return axiosInstance.get(
      `${oneTimeTokenLogin}/?token=${encodeURIComponent(token)}`,
    );
  },
};
