import { InterviewerOverrideType } from "@/types/assessment";
import {
  InterviewerFilterParamType,
  InterviewerUpdateType,
  NewInterviewerType,
} from "@/types/interviewer";

import axiosInstance from "@/services/api/axiosInstance";

const interviewers = "interviewer-profile";
const interviewSlots = "interview-slots-v2";
const assessment = "assessments";
const interviewerDashboard = "interviewer-dashboard";

const interviewerAvailabilityOverride = "interviewer-availability-override";

const interviewerResumeAvailability = "interviewer-resume-availability";

export default {
  getInterviewers(params: InterviewerFilterParamType) {
    return axiosInstance.get(`${interviewers}/`, { params });
  },
  postInterviewer(data: NewInterviewerType) {
    return axiosInstance.post(`${interviewers}/`, data);
  },
  getInterviewerById(id: number) {
    return axiosInstance.get(`${interviewers}/${id}/`);
  },
  patchInterviewer(data: InterviewerUpdateType) {
    return axiosInstance.patch(`${interviewers}/${data.id}/`, data);
  },

  // overrides
  getOverridesByInterviewerId(params: { interviewer_profile: number }) {
    return axiosInstance.get(`${interviewerAvailabilityOverride}`, { params });
  },
  postOverride(data: Omit<InterviewerOverrideType, "id">) {
    return axiosInstance.post(`${interviewerAvailabilityOverride}/`, data);
  },
  patchOverride(id: number, data: Partial<InterviewerOverrideType>) {
    return axiosInstance.patch(
      `${interviewerAvailabilityOverride}/${id}/`,
      data,
    );
  },
  deleteOverride(id: number) {
    return axiosInstance.delete(`${interviewerAvailabilityOverride}/${id}`);
  },

  //
  postAssessment(data: any) {
    return axiosInstance.post(`${assessment}/`, data);
  },
  patchAssessment(id: number, data: any) {
    return axiosInstance.patch(`${assessment}/${id}/`, data);
  },
  getInterviewerDashboardStats(params?: {
    start_date?: string;
    end_date?: string;
  }) {
    return axiosInstance.get(`${interviewerDashboard}/`, {
      params: { ...params },
    });
  },

  getInterviewerSlots(params: { interviewer_profile: number }) {
    return axiosInstance.get(`${interviewSlots}/`, { params });
  },

  postInterviewerSlots(data: {
    interviewer_profile: number;
    availability: object;
    valid_from: string;
  }) {
    return axiosInstance.post(`${interviewSlots}/`, data);
  },

  postInterviewerResumeAvailability() {
    return axiosInstance.post(`${interviewerResumeAvailability}/`);
  },
};
