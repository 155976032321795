import { queryClient } from "@/pages/_app";
import { salaryToLPA } from "@/views/jobs/utils";
import { RhToast } from "@rhythm-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";

import { TRelevantJob } from "@/types/job";
import { JobRequirementSubmissionNameType } from "@/types/utils";

import api from "@/services/api";
import { useGlobalJobStages } from "@/services/apiHooks/recruiter";

import { Button } from "@/components/ui/Button";

type TAllJobsProps = {
  candidate_id: number;
};
type TMatchButtonProps = {
  status:
    | {
        id: number;
        name: JobRequirementSubmissionNameType;
      }
    | undefined;
  candidate_id: number;
  job: TRelevantJob;
};

const AllJobs = (props: TAllJobsProps) => {
  const {
    data: jobs,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ["CANDIDATE_RELEVANT_JOBS", { candidateId: props.candidate_id }],
    queryFn: () => api.recruiter.getCandidateRelevantJobs(props.candidate_id),
    select: (res) => res.data,
    enabled: !!props.candidate_id,
  });

  const { data: globalJobStages } = useGlobalJobStages();
  const underscreeningStatus = useMemo(
    () => globalJobStages?.find((item) => item.name === "Underscreening"),
    [globalJobStages],
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isFetching) {
    return <div>Refreshing available jobs to match...</div>;
  }

  if (!jobs?.length) {
    return <div>No relevant jobs to show</div>;
  }

  return (
    <div>
      {jobs.map((job) => {
        return (
          <div
            key={job.id}
            className="mb-2 mt-2 flex items-center justify-between rounded-md border border-gray-400 p-2 hover:border-gray-950"
          >
            <div>
              <div>
                {job.handpick_company ?? job.company_name}{" "}
                {!!job.is_handpicked && (
                  <span className="text-orange-500"> [CH]</span>
                )}{" "}
                | {job.title} | {salaryToLPA(job.min_salary)} -{" "}
                {salaryToLPA(job.max_salary)} lpa
              </div>
            </div>
            <MatchButton
              candidate_id={props.candidate_id}
              status={underscreeningStatus}
              job={job}
            />
          </div>
        );
      })}
    </div>
  );
};

const MatchButton = (props: TMatchButtonProps) => {
  const { mutate, status } = useMutation({
    mutationFn: (payload: {
      job_id: number;
      title: string;
      company_name: string;
    }) => {
      if (typeof props.status?.id === "undefined")
        return Promise.reject(new Error("review stage id not found"));
      return api.recruiter.patchBulkCandidateStageStatus({
        candidate_ids: [props.candidate_id],
        stage: props.status?.id,
        job_id: payload.job_id,
      });
    },
    onSuccess(_, args) {
      queryClient.invalidateQueries({
        queryKey: ["CANDIDATES", "LIST"],
      });
      queryClient.invalidateQueries({
        queryKey: ["CANDIDATE_ACTIVITIES"],
      });
      queryClient.invalidateQueries({
        queryKey: ["ACTIVITY_LOG_ACTORS"],
      });
      queryClient.invalidateQueries({
        queryKey: ["JOB_REQUIREMENT_STATUS"],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "CANDIDATES_JOB_STAGES",
          "ITEM",
          { candidateId: props.candidate_id },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "CANDIDATE_RELEVANT_JOBS",
          { candidateId: props.candidate_id },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ["JOBS", "ITEM", { jobId: args.job_id }],
      });

      RhToast.success(
        `Candidate has been moved to underscreening stage for the job ${args.title} at ${args.company_name}`,
        {
          position: "bottom-right",
        },
      );
    },
  });

  return (
    <Button
      isLoading={status === "loading"}
      disabled={status === "loading"}
      onClick={() =>
        mutate({
          job_id: props.job.id,
          title: props.job.title,
          company_name: props.job.company_name,
        })
      }
    >
      Match
    </Button>
  );
};

export default AllJobs;
