import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { RhLoader } from "@rhythm-ui/react";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium text-white disabled:text-gray-400 rounded ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none",
  {
    variants: {
      variant: {
        default: "bg-primary-600 hover:bg-primary-800 disabled:bg-gray-100",
        secondary:
          "text-primary-600 border border-primary-600 disabled:border-gray-200 hover:bg-[#F3F9FF]",
        tertiary:
          "text-gray-700 hover:text-primary-600 hover:bg-blue-50 disabled:bg-gray-100",
        tertiaryBlue: "text-primary-600 hover:bg-blue-50 disabled:bg-gray-100",
        danger: "bg-danger-600 hover:bg-danger-800 disabled:bg-gray-100",
        secondaryDanger:
          "text-red-600 border border-red-600 disabled:border-gray-200 hover:bg-red-50",
        tertiaryDanger: "text-red-600 hover:bg-red-50 disabled:bg-gray-100",
        tertiaryYellow:
          "text-yellow-600 hover:bg-yellow-50 disabled:bg-gray-100",
        secondaryYellow:
          "text-yellow-600 border border-yellow-600 disabled:border-gray-200 hover:bg-yellow-50",
      },
      size: {
        default: "h-10 px-tsm py-tnano text-[16px]",
        sm: "h-8 py-tpico px-tmini text-[14px]",
        icon: "h-10 p-[10px]",
        iconSm: "h-8 p-[6px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading,
      disabled,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn("relative", buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        disabled={disabled || isLoading}
      >
        <div className={`absolute ${!isLoading ? "hidden" : "left-0 right-0"}`}>
          <RhLoader size="sm" className="mx-auto" />
        </div>
        <span
          className={`inline-flex w-full items-center gap-tnano ${
            isLoading ? "invisible" : ""
          }`}
        >
          {props.children}
        </span>
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
