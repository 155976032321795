import "../styles/globals.css";
/* routes don't execute sometimes. A nextjs bug, that has a workaround. import a empty css file from _app.js */
import "../styles/empty.css";

/* quote from https://github.com/vercel/next.js/issues/5264#issuecomment-424000127  */
/* Can be worked around by importing an empty css file from _app.js
It's caused by the chunks having a deferred module dependency on the style chunk, 
but the style chunk is never loaded, so the new chunk never executes. If you depend on a css file from
the _app.js file, then the style chunk will always be available and the new route executes once it's loaded. */

import { getCookies } from "cookies-next";
import type { AppProps } from "next/app";

import { Ability } from "@casl/ability";
import { RhThemeProvider, RhToastContainer } from "@rhythm-ui/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import NProgress from "nprogress";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { Provider } from "react-redux";

import { AbilityContext } from "@/context/Can";

import api from "@/services/api";

import store from "@/redux/store";

import { NODE_ENV } from "@/constants";

import { AuthContext, AuthProvider } from "../context/AuthContext";

import "nprogress/nprogress.css";
import "../styles/nprogress.css";

import Router from "next/router";
import Script from "next/script";

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import useAuth from "@/hooks/useAuth";

import showToastMessages from "@/helpers/showToastMessages";

// router loading event listner
NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

Modal.setAppElement("#theme-provider");

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, staleTime: 3 * 60 * 1000 },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      const showToastOnError = query?.meta?.showToastOnError ?? true;
      if (showToastOnError) {
        showToastMessages(error);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      const showToastOnError = mutation?.meta?.showToastOnError ?? true;
      if (showToastOnError) {
        showToastMessages(error);
      }
    },
  }),
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [caslRule, setCaslRule] = useState(new Ability([]));
  const showBugsnap = false;

  const setAbilityRules = async () => {
    const { roles: rolesAsString, zelAccess, zelRefresh } = getCookies();

    if (rolesAsString && zelAccess && zelRefresh) {
      const {
        data: { permissions },
      } = await api.auth.getPermissionRules();
      // TODO: handle error
      const MyAbility = new Ability(permissions || []);
      setCaslRule(MyAbility);
    } else {
      const MyAbility = new Ability([]);
      setCaslRule(MyAbility);
    }
  };

  return (
    <>
      {NODE_ENV == "production" && typeof window !== "undefined" && (
        <Telemetry />
      )}
      {showBugsnap && (
        <Script
          src="https://uat-qa-widget.netlify.app/dist/bundle.js"
          type="text/javascript"
          id="qa-widget-codemonk"
          data-id="10002"
          data-environment="development"
          data-jira-domain="https://codemonkhq.atlassian.net"
        ></Script>
      )}
      <RhThemeProvider theme="light" className="not-prose">
        <div className="app h-full">
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <AuthSetter setAbilityRules={setAbilityRules}>
                  <AbilityContext.Provider value={caslRule}>
                    <RhToastContainer position="bottom-right" />
                    <Component {...pageProps} />
                  </AbilityContext.Provider>
                </AuthSetter>
              </AuthProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </Provider>
        </div>
      </RhThemeProvider>
    </>
  );
};

const AuthSetter = ({
  children,
  setAbilityRules,
}: {
  children: React.ReactNode;
  setAbilityRules: () => Promise<void>;
}) => {
  const { auth } = useContext(AuthContext);

  const { setUserAuth } = useAuth();

  useEffect(() => {
    setUserAuth(); // to set auth content on first load
  }, []);

  useEffect(() => {
    setAbilityRules();
  }, [auth.isAuthenticated]);

  return <>{children}</>;
};

export default MyApp;

const Telemetry = () => {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
  `}
      </Script>
      <Script defer id="clarity-script">
        {import("../constants/clarity")}
      </Script>
      <Script defer id="apollo-script">
        {import("../constants/apollo")}
      </Script>
    </>
  );
};
