import {
  CompanyFilterParamType,
  CompanyType,
  CompanyUpdateType,
  CompanyUserType,
  CompanyUserUpdateType,
  NewCompanyType,
  NewCompanyUserType,
} from "@/types/company";
import { ClientActivityLog } from "@/types/employer-activity-log";
import {
  ContactDetailsType,
  JobFilterParamType,
  JobType,
  JobUpdateType,
  NewJobType,
} from "@/types/job";
import { JobRequirementProgressType, PaginatedResponse } from "@/types/utils";

import axiosInstance from "@/services/api/axiosInstance";

const companies = "companies";
const industryDomains = "industry-domains";
const jobRequirement = `job-requirement`;
const jobRequirementsCount = `job-requirements-count`;
const contacts = "contacts";
const companySizes = "company-sizes";
const companyStage = "company-stage";
const companyContacts = "company-contacts";
const companyUsers = "company-contacts-v2";
const companyCurrentUser = "company-contacts-v2-profile";
const requestDemo = "request-demo";
const candidateJobApplication = "candidate-job-application";
const activityLog = "activity-log";
const companyContactChangePassword = "company-contact-change-password";
const revokeReason = "revoke-reason";
const revokeApplicationReason = "revoke-application-reason";
const companyFundingRounds = "company-funding-rounds";
const companyBusinessTypes = "company-business-types";
const jobRequirementStatus = `job-requirement-candidate-count-with-status`;

const jobRequirementsNotes = `job-requirements-notes`;

const jobRequirementFileUpload = `job-requirement-file-upload`;

const employerActivityLogs = `employer-activity-logs`;
const employerNoteActivity = `employer-note-activity`;
const requestTimeSlots = `request-time-slots`;
const offerLetterFileUpload = `offer-letter-file-upload`;
const issueOfferLetter = `issue-offer-letter`;
const markCandidateViewStatus = `mark-candidate-view-status`;
const companyContactResendInvite = `company-contact-resend-invite`;

const interviewRoundCategories = `interview-round-categories`;

export default {
  getCompanies(data: CompanyFilterParamType) {
    return axiosInstance.get<PaginatedResponse<CompanyType>>(`${companies}/`, {
      params: data,
    });
  },
  getCompany(id: number) {
    return axiosInstance.get<CompanyType>(`${companies}/${id}/`);
  },
  postCompany(data: NewCompanyType) {
    return axiosInstance.post(`${companies}/`, data);
  },
  patchCompany(data: CompanyUpdateType) {
    return axiosInstance.patch(`${companies}/${data.id}/`, data);
  },

  // CONTACT
  getContact(id: number) {
    return axiosInstance.get(`${contacts}/${id}/`);
  },
  postContact(data: any) {
    return axiosInstance.post(`${contacts}/`, data);
  },
  patchContact(id: number, data: any) {
    return axiosInstance.patch(`${contacts}/${id}/`, data);
  },
  deleteContact(id: number) {
    return axiosInstance.delete(`${contacts}/${id}/`);
  },
  getHiringManger(params?: { search?: string; company__in?: number }) {
    return axiosInstance.get(`${contacts}/`, { params });
  },
  // postHiringManger(data: ContactType) {
  //   return axiosInstance.post(`${contacts}/`, data);
  // },
  getHiringContacts(params?: { search?: string; company?: number }) {
    return axiosInstance.get<PaginatedResponse<ContactDetailsType>>(
      `${companyContacts}/.`,
      { params },
    );
  },

  //company-contact-v2 start
  getCompanyUsers(params?: {
    search?: string;
    company?: number;
    page?: number;
    // profile?: string;
  }) {
    return axiosInstance.get<PaginatedResponse<CompanyUserType>>(
      `${companyUsers}/.`,
      { params },
    );
  },

  getCurrentCompanyUser() {
    return axiosInstance.get<CompanyUserType>(`${companyCurrentUser}/`);
  },

  getCompanyUser(id: number) {
    return axiosInstance.get<CompanyUserType>(`${companyUsers}/${id}/`);
  },

  patchCompanyUser({ id, ...payload }: CompanyUserUpdateType) {
    return axiosInstance.patch(`${companyUsers}/${id}/`, payload);
  },

  postCompanyUser(data: NewCompanyUserType) {
    return axiosInstance.post(`${companyUsers}/`, data);
  },

  patchCompanyContactChangePassword({
    id,
    ...payload
  }: {
    old_password?: string;
    password: string;
    id: number;
  }) {
    return axiosInstance.patch(
      `${companyContactChangePassword}/${id}/`,
      payload,
    );
  },

  //company-contact-v2 start

  // Company Business Types
  getCompanyBusinessType(params?: { search: string }) {
    return axiosInstance.get(`${companyBusinessTypes}/`, { params });
  },

  //COMPANY FUNDING
  getCompanyFunding() {
    return axiosInstance.get(`${companyFundingRounds}/`);
  },

  //get count

  getJobRequirementsCount(params?: { company_id: number }) {
    return axiosInstance.get(`${jobRequirementsCount}/`, { params });
  },

  getJobs(data?: JobFilterParamType) {
    // Check if status contains the special format
    if (
      data?.status &&
      typeof data.status === "string" &&
      data.status.includes("&status=")
    ) {
      // Clone the data
      const clonedData = { ...data };

      // Convert the status string to an array
      const statusValues = clonedData.status?.split("&status=");

      // Delete the original status property
      delete clonedData.status;

      // Create URLSearchParams with the modified data
      const params = new URLSearchParams();

      // Add all other parameters
      Object.entries(clonedData).forEach(([key, value]) => {
        if (value !== undefined) {
          params.append(key, String(value));
        }
      });

      // Add all status values
      statusValues?.forEach((status) => {
        params.append("status", status);
      });

      // Build the URL with the properly formatted query string
      const url = `${jobRequirement}/?${params.toString()}`;

      return axiosInstance.get<PaginatedResponse<JobType>>(url);
    }

    // Regular case - proceed as normal
    return axiosInstance.get<PaginatedResponse<JobType>>(`${jobRequirement}/`, {
      params: data,
    });
  },
  getJobById(id: number | string) {
    return axiosInstance.get<JobType>(`${jobRequirement}/${id}/`);
  },
  postJob(data: NewJobType) {
    return axiosInstance.post(`${jobRequirement}/`, data);
  },
  patchJob({ id, ...payload }: JobUpdateType) {
    return axiosInstance.patch(`${jobRequirement}/${id}/`, payload);
  },

  postJobRequirementNotes(jobId: string, payload: any) {
    return axiosInstance.post(`${jobRequirementsNotes}/${jobId}/`, payload);
  },

  uploadJobPdf(data: any) {
    return axiosInstance.post(`${jobRequirementFileUpload}/`, data);
  },

  updateJobPdfStatus(id: number, data: any) {
    return axiosInstance.patch(`${jobRequirementFileUpload}/${id}/`, data);
  },

  getInterviewRoundCategories() {
    return axiosInstance.get<{ name: string; id: number }[]>(
      `${interviewRoundCategories}`,
    );
  },

  postCandidateJobApplication(data: {
    job_requirement: number | undefined;
    // accepted_by: number;
  }) {
    return axiosInstance.post(`${candidateJobApplication}/`, data);
  },
  getCandidateRevokeJobApplicationReasons(params?: { pagination?: boolean }) {
    return axiosInstance.get(`${revokeReason}/`, { params });
  },
  postRevokeCandidateJobApplication: (data: {
    job_requirement: number;
    reasons: number[];
    other_reason: string;
  }) => {
    return axiosInstance.post(`${revokeApplicationReason}/`, data);
  },

  getCandidateJobApplicationActivity: (params: {
    candidate_id: number;
    job_requirement_id: number;
    type: "opportunities";
    pagination?: boolean;
  }) => {
    return axiosInstance.get(`${activityLog}/`, {
      params,
    });
  },

  getJobRequirementStatus(params?: { job_requirement_id: number }) {
    return axiosInstance.get<{ data: JobRequirementProgressType }>(
      `${jobRequirementStatus}/`,
      { params },
    );
  },

  // industries
  getIndustries(params?: { search?: string }) {
    return axiosInstance.get(`${industryDomains}/`, { params });
  },

  getCompanySizes() {
    return axiosInstance.get(`${companySizes}`);
  },
  getCompanyStages() {
    return axiosInstance.get(`${companyStage}`);
  },

  // contact page
  postRequestDemo(data: { name: string; email: string; mobile: string }) {
    return axiosInstance.post(`${requestDemo}/`, data);
  },

  // activities
  // getRecruiters() {
  //   return axiosInstance.get(`${user}/`);
  // },
  getCompanyActivity(params: {
    candidate_id: number;
    job_requirement_id: number;
    page: number;
  }) {
    return axiosInstance.get<PaginatedResponse<ClientActivityLog>>(
      `${employerActivityLogs}/`,
      { params },
    );
  },
  postCompanyNote(data: {
    candidate_id: number;
    note: string;
    job_requirement_id: number;
  }) {
    return axiosInstance.post(`${employerNoteActivity}/`, data);
  },

  patchCandidateActivityNote({ id, note }: { id: number; note: string }) {
    return axiosInstance.patch(`${employerNoteActivity}/${id}/`, {
      note,
    });
  },

  postRequestCandidateTimeSlot(data: {
    candidate_id: number;
    job_requirement_id: number;
    timeslot_data: string;
  }) {
    return axiosInstance.post(`${requestTimeSlots}/`, data);
  },

  uploadIssueOfferLetter(data: any) {
    return axiosInstance.post(`${offerLetterFileUpload}/`, data);
  },

  postIssueOfferLetterToCandidate(data: {
    submission: number;
    salary: number;
    offer_letter_file?: number;
    joining_date: string;
  }) {
    return axiosInstance.post(`${issueOfferLetter}/`, data);
  },

  postCandidateReadStatus(data: {
    job_requirement: number;
    candidate: number;
  }) {
    return axiosInstance.post(`${markCandidateViewStatus}/`, data);
  },

  postCompanyContactResendInvite(data: { user: number }) {
    return axiosInstance.post(`${companyContactResendInvite}/`, data);
  },
};
